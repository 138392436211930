import AOS from 'aos';
import initAnimationClasses from './aos-animations/aos-animations';
import initFormFiles from './form/_form-file';
import initMultiselects from './form/_form-multiselect';
import initFileLinks from './helpers/file-links';
import initFormSelects from './helpers/form-inputs';

var documentReady = function () {
    initFormSelects();
    initFormFiles();
    initMultiselects();
    initFileLinks();
    initAnimationClasses();

    AOS.init({
        once: true,
        disable: 'mobile',
    });
};

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    documentReady();
} else {
    document.addEventListener('DOMContentLoaded', documentReady);
}

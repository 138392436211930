export default function addAnimationClasses() {
    const setAnimationAttributes = (element, animateType, duration, delay) => {
        element.setAttribute('data-aos', animateType);
        element.setAttribute('data-aos-duration', duration);
        element.setAttribute('data-aos-delay', delay);
    };

    document.querySelectorAll('.animate-right').forEach(element => {
        setAnimationAttributes(element, 'fade-right', '600', '0');
    });
    document.querySelectorAll('.animate-left').forEach(element => {
        setAnimationAttributes(element, 'fade-left', '600', '0');
    });
    document.querySelectorAll('.text-columns__row').forEach(element => {
        setAnimationAttributes(element, 'fade-up', '500', '0');
    });
    // document.querySelectorAll('.animate-slide').forEach(element => {
    //     setAnimationAttributes(element, 'fade-up', '500', '0');
    // });
}
